import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/auth-boxed.css";
import "./assets/css/main.css";
import "./assets/css/dash_1.css";
import "./assets/css/perfect-scrollbar.css";
import "./assets/css/structure.css";
import "./assets/css/waves.min.css";
import "./assets/css/custom.css";
import Login from "./pages/Login";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Register from "./pages/Register";
import Base from "./components/Base";

import ProtectedRoute from "./components/ProtectedRoute";
import AuthRoot from "./components/AuthRoot";

import ErrorPage from "./pages/ErrorPage";

import ErrorBoundary from "./components/ErrorBoundry";

import { lazy, Suspense, useEffect } from "react";
const Enquiries = lazy(() => import("./pages/Enquiry/Enquiries"));
const CreateEnquiry = lazy(() => import("./pages/Enquiry/CreateEnquiry"));
const CreateApplication = lazy(() =>
  import("./pages/Application/CreateApplication")
);
const EditEnquiry = lazy(() => import("./pages/Enquiry/EditEnquiry"));
const StudentDashboard = lazy(() => import("./pages/StudentDashboard"));
const Applications = lazy(() => import("./pages/Application/Applications"));
const EditApplication = lazy(() =>
  import("./pages/Application/EditApplication")
);
const UserProfile = lazy(() => import("./pages/User"));
const Search = lazy(() => import("./pages/Search"));
const University = lazy(() => import("./pages/University"));
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Base />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "enquiries",
          element: (
            <Suspense>
              <Enquiries />
            </Suspense>
          ),
        },
        {
          path: "enquiry",
          element: <Navigate to="/enquiries" replace />,
        },
        {
          path: "enquiry/edit",
          element: <Navigate to="/enquiries" replace />,
        },
        {
          path: "enquiry/create/",
          element: (
            <Suspense>
              <CreateEnquiry />
            </Suspense>
          ),
        },
        {
          path: "enquiry/edit/:enquiryId",
          element: (
            <Suspense>
              <EditEnquiry />
            </Suspense>
          ),
        },
        {
          path: "student",
          element: <Navigate to="/enquiries" replace />,
        },
        {
          path: "student/:enqId",
          element: (
            <Suspense>
              <StudentDashboard />
            </Suspense>
          ),
        },
        {
          path: "applications",
          element: (
            <Suspense>
              <Applications createApp={true} />
            </Suspense>
          ),
        },
        {
          path: "application",
          element: <Navigate to="/applications" replace />,
        },
        {
          path: "application/edit",
          element: <Navigate to="/applications" replace />,
        },
        {
          path: "application/create/:appID?",
          element: (
            <Suspense>
              <CreateApplication />
            </Suspense>
          ),
        },
        {
          path: "application/edit/:appId",
          element: (
            <Suspense>
              <EditApplication />
            </Suspense>
          ),
        },
        {
          path: "user-profile",
          element: (
            <Suspense>
              <UserProfile />
            </Suspense>
          ),
        },
        {
          path: "search",
          element: (
            <Suspense>
              <Search />
            </Suspense>
          ),
        },
        {
          path: "university",
          element: (
            <Suspense>
              <University />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "login",
      element: (
        <AuthRoot>
          <Login />
        </AuthRoot>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "register",
      element: (
        <AuthRoot>
          <Register />
        </AuthRoot>
      ),
      errorElement: <ErrorPage />,
    },
  ]
  // { basename: "/oeccrm" }
);

function App() {
  return (
    <>
      <ErrorBoundary>
        <RouterProvider router={router}></RouterProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
