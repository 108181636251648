import React, { useReducer, useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputBox from "../components/UI/Form/InputBox";
import { ajaxCall } from "../helpers/ajaxCall";
import { Link } from "react-router-dom";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password2: "",
  username: "",
};

const reducer = function (state, action) {
  if (action.all) {
    return action.value;
  }
  return { ...state, [action.type]: action.value };
};

function Register() {
  const [formState, setFormState] = useState({
    error: "",
    isSubmitting: false,
    successMsg: "",
  });
  const [formData, dispatchInputChange] = useReducer(reducer, initialState);

  const doRegister = async (e) => {
    e.preventDefault();
    setFormState({ error: "", isSubmitting: false, successMsg: "" });
    if (formData.password?.length < 8) {
      setFormState({
        successMsg: "",
        isSubmitting: false,
        error: "Password should be atleast 8 characters long",
      });
      return;
    }
    console.log("aftter the validatio");
    console.log(formData.password);
    console.log(formData.password2);
    const passRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    console.log(passRegex.test(formData.password));
    if (!passRegex.test(formData.password)) {
      setFormState({
        successMsg: "",
        isSubmitting: false,
        error:
          "Password is not strong, Please should contain atleast 1 lowercase, uppercase and special character.",
      });
      return;
    }
    if (formData.password !== formData.password2) {
      setFormState({
        successMsg: "",
        isSubmitting: false,
        error: "Password Doesn't match, Please try again...",
      });
      return;
    }
    console.log("at calling");
    const response = await ajaxCall(
      "registration/",
      {
        // "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      "POST",
      JSON.stringify(formData)
    );
    // console.log(response);
    // try {
    if (response?.isNetwork) {
      setFormState((oldState) => {
        return {
          ...oldState,
          successMsg: "",
          isSubmitting: false,
          error: "Some Problem Occured, Please try again",
        };
      });
      return;
      // throw new Error("", { status: 401 });
      // navigate("/error");
    }
    if (response?.status && response?.status === 400 && response?.notOk) {
      setFormState({
        isSubmitting: false,
        error:
          "Username or EmailId is already Registered, Please change userName and try again...",
        successMsg: "",
      });
      return;
    }
    setFormState((oldState) => {
      return {
        ...oldState,
        isSubmitting: false,
        error: "",
        successMsg:
          "Registered Succesfully, You will be able to login after Admin approval",
      };
    });
    dispatchInputChange({
      all: true,
      value: initialState,
    });
  };
  return (
    <div className="form">
      <div className="auth-container">
        <div className="container mx-auto align-self-center">
          <div className="row ">
            <div className="col-md-12">
              <h1
                className="text-center"
                style={{ marginBottom: "100px", marginTop: "50px" }}
              >
                Welcome To flyUrdream
              </h1>
            </div>
            <div className="col-md-4 col-4 d-flex flex-row align-self-center mx-auto flexCols">
              <div className="text-center">
                <img
                  src="https://flyurdream.com/wp-content/uploads/2022/11/FUD-logo1-1-1-3.png"
                  alt="FlyUr Dream Logo"
                  className="logo-register"
                />
              </div>
            </div>
            <div className="col-md-8 col-8">
              <div className="card mt-3 mb-3 neumorphism-box nmb">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-md-12">
                      <h2 className="text-center">Agent's Register</h2>
                      <p className="text-center">Fill Form To register</p>
                    </div>
                    <Form className="row" onSubmit={doRegister}>
                      <Form.Group
                        className="mb-3 col-md-6"
                        controlId="stuEmail"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          name="firstName"
                          value={formData.first_name}
                          onChange={(e) =>
                            dispatchInputChange({
                              type: "first_name",
                              value: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 col-md-6"
                        controlId="last_name"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          required
                          type="last_name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={(e) =>
                            dispatchInputChange({
                              type: "last_name",
                              value: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 col-md-6" controlId="email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          required
                          name="email"
                          value={formData.email}
                          onChange={(e) =>
                            dispatchInputChange({
                              type: "email",
                              value: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 col-md-6"
                        controlId="username"
                      >
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          name="username"
                          value={formData.username}
                          onChange={(e) =>
                            dispatchInputChange({
                              type: "username",
                              value: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 col-md-6"
                        controlId="password"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          required
                          name="password"
                          value={formData.password}
                          onChange={(e) =>
                            dispatchInputChange({
                              type: "password",
                              value: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 col-md-6"
                        controlId="password2"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          required
                          type="password"
                          name="password2"
                          value={formData.password2}
                          onChange={(e) =>
                            dispatchInputChange({
                              type: "password2",
                              value: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <div className="col-12 text-center">
                        <Button
                          variant="primary"
                          type="submit"
                          // onClick={doLogin}
                          disabled={formState.isSubmitting}
                        >
                          {formState.isSubmitting ? "Registering" : "Register"}
                        </Button>

                        {formState.error ? (
                          <p className="dengor">{formState.error}</p>
                        ) : (
                          ""
                        )}
                        {formState.successMsg ? (
                          <p className="greenClr">{formState.successMsg}</p>
                        ) : (
                          ""
                        )}
                        <div className="mt-3">
                          <Link
                            className="btn btn-primary"
                            // onClick={doLogin}
                            to="/login"
                          >
                            Already Registered ? Login
                          </Link>
                        </div>
                      </div>
                    </Form>

                    {/* <div className="col-12">
                  <div className="text-center">
                    <p className="mb-0">
                      Dont't have an account ?{" "}
                      <Link to="register" className="text-warning">
                        Register
                      </Link>
                    </p>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="iconWrapper">
            <a
              className="icon-10 facebook loginPageIcon"
              href="#"
              title="Facebook"
            >
              <div class="ir">
                <svg
                  viewBox="0 0 512 512"
                  preserveAspectRatio="xMidYMid meet"
                  width="512"
                  height="512"
                >
                  <path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"></path>
                </svg>
              </div>
            </a>
            <a
              className="icon-17 linkedin loginPageIcon"
              href="#"
              title="LinkedIn"
            >
              <div class="ir">
                <svg
                  viewBox="0 0 512 512"
                  preserveAspectRatio="xMidYMid meet"
                  width="512"
                  height="512"
                >
                  <path d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z"></path>
                </svg>
              </div>
            </a>
            <a
              className="icon-15 instagram loginPageIcon"
              href="#"
              title="Instagram"
            >
              <div class="ir">
                <svg
                  viewBox="0 0 512 512"
                  preserveAspectRatio="xMidYMid meet"
                  width="512"
                  height="512"
                >
                  <path d="M256 109.3c47.8 0 53.4 0.2 72.3 1 17.4 0.8 26.9 3.7 33.2 6.2 8.4 3.2 14.3 7.1 20.6 13.4 6.3 6.3 10.1 12.2 13.4 20.6 2.5 6.3 5.4 15.8 6.2 33.2 0.9 18.9 1 24.5 1 72.3s-0.2 53.4-1 72.3c-0.8 17.4-3.7 26.9-6.2 33.2 -3.2 8.4-7.1 14.3-13.4 20.6 -6.3 6.3-12.2 10.1-20.6 13.4 -6.3 2.5-15.8 5.4-33.2 6.2 -18.9 0.9-24.5 1-72.3 1s-53.4-0.2-72.3-1c-17.4-0.8-26.9-3.7-33.2-6.2 -8.4-3.2-14.3-7.1-20.6-13.4 -6.3-6.3-10.1-12.2-13.4-20.6 -2.5-6.3-5.4-15.8-6.2-33.2 -0.9-18.9-1-24.5-1-72.3s0.2-53.4 1-72.3c0.8-17.4 3.7-26.9 6.2-33.2 3.2-8.4 7.1-14.3 13.4-20.6 6.3-6.3 12.2-10.1 20.6-13.4 6.3-2.5 15.8-5.4 33.2-6.2C202.6 109.5 208.2 109.3 256 109.3M256 77.1c-48.6 0-54.7 0.2-73.8 1.1 -19 0.9-32.1 3.9-43.4 8.3 -11.8 4.6-21.7 10.7-31.7 20.6 -9.9 9.9-16.1 19.9-20.6 31.7 -4.4 11.4-7.4 24.4-8.3 43.4 -0.9 19.1-1.1 25.2-1.1 73.8 0 48.6 0.2 54.7 1.1 73.8 0.9 19 3.9 32.1 8.3 43.4 4.6 11.8 10.7 21.7 20.6 31.7 9.9 9.9 19.9 16.1 31.7 20.6 11.4 4.4 24.4 7.4 43.4 8.3 19.1 0.9 25.2 1.1 73.8 1.1s54.7-0.2 73.8-1.1c19-0.9 32.1-3.9 43.4-8.3 11.8-4.6 21.7-10.7 31.7-20.6 9.9-9.9 16.1-19.9 20.6-31.7 4.4-11.4 7.4-24.4 8.3-43.4 0.9-19.1 1.1-25.2 1.1-73.8s-0.2-54.7-1.1-73.8c-0.9-19-3.9-32.1-8.3-43.4 -4.6-11.8-10.7-21.7-20.6-31.7 -9.9-9.9-19.9-16.1-31.7-20.6 -11.4-4.4-24.4-7.4-43.4-8.3C310.7 77.3 304.6 77.1 256 77.1L256 77.1z"></path>
                  <path d="M256 164.1c-50.7 0-91.9 41.1-91.9 91.9s41.1 91.9 91.9 91.9 91.9-41.1 91.9-91.9S306.7 164.1 256 164.1zM256 315.6c-32.9 0-59.6-26.7-59.6-59.6s26.7-59.6 59.6-59.6 59.6 26.7 59.6 59.6S288.9 315.6 256 315.6z"></path>
                  <circle cx="351.5" cy="160.5" r="21.5"></circle>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
